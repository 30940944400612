@use '@angular/material' as mat;
@use 'swiper/scss';

@import 'app/colors';

$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

$mdi-font-path: '~@mdi/font/fonts/';
@import '~@mdi/font/scss/materialdesignicons.scss';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ampapp-primary: mat.define-palette($md-ampamber);
$ampapp-accent: mat.define-palette(mat.$indigo-palette);

// The warn palette is optional (defaults to red).
$ampapp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ampapp-theme: mat.define-dark-theme(
    $ampapp-primary,
    $ampapp-accent,
    $ampapp-warn
);

$foreground: map-get($ampapp-theme, foreground);
$foreground: map_merge(
    $foreground,
    (
        text: $white,
    )
);

$background: map-get($ampapp-theme, background);
$background: map_merge(
    $background,
    (
        background: $black,
        'app-bar': $black,
    )
);

$ampapp-theme: map_merge(
    $ampapp-theme,
    (
        foreground: $foreground,
        background: $background,
    )
);

$custom-typography: mat.define-typography-config(
    $font-family: 'Avenir Next',
    $headline: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 500),
);
@include mat.all-component-typographies($custom-typography);

@include mat.all-component-themes($ampapp-theme);

.mat-list-item[disabled] {
    color: $white-disabled;
}

body .mat-menu-item {
    height: 64px;
    font-size: 24px;
}

.cdk-drag-preview .mat-list-item-content {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 16px;
    background-color: #242424;
    box-sizing: border-box;
    width: 100vw;
    @include mat.elevation(4);
    @include mat.elevation-transition;

    > * {
        height: inherit;
    }

    .mat-list-text {
        flex: 1;
    }
}
.mat-toolbar {
    // was orig #070707 - very hard to see
    border-bottom: 1px solid $header-bar-border;
}
/* You can add global styles to this file, and also import other style files */

* {
    font-family: 'Avenir Next';
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    color: $white;
    background-color: $black;
}

// needs to be here because i cant otherwise pierce the shadow dom
.settings .mat-expansion-panel-body {
    background-color: lighten($black, 11%);
    padding-top: 16px;
}

/*
  Fonts:
    Avenir Next (normal + italic)
    - UltraLight 100
    - Regular 400
    - Medium 500
    - Demi 600
    - Bold 700
    - Heavy 800
*/

@font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/AvenirNext-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: italic;
    font-weight: 700;
    src: url('assets/fonts/AvenirNext-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/AvenirNext-DemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: italic;
    font-weight: 600;
    src: url('assets/fonts/AvenirNext-DemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/AvenirNext-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: italic;
    font-weight: 800;
    src: url('assets/fonts/AvenirNext-HeavyItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: italic;
    font-weight: 400;
    src: url('assets/fonts/AvenirNext-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/AvenirNext-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: italic;
    font-weight: 500;
    src: url('assets/fonts/AvenirNext-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/AvenirNext-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 100;
    src: url('assets/fonts/AvenirNext-UltraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    font-style: italic;
    font-weight: 100;
    src: url('assets/fonts/AvenirNext-UltraLightItalic.ttf') format('truetype');
}

$scrollbarblack: #2e2e2e;
$scrollbardivider: #424242;
.frontpanel {
    ::-webkit-scrollbar-thumb {
        background-color: $scrollbarblack;
    }

    ::-webkit-scrollbar-track {
        background-color: $scrollbarblack;
        border-left: 1px solid $scrollbardivider;
    }

    ::-webkit-scrollbar {
        width: 100px;
    }

    ::-webkit-scrollbar-button {
        border-style: 0;
        border-left: 1px solid $scrollbardivider;
        height: 50px;
        background-size: 50px 50px;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $scrollbarblack;
    }

    ::-webkit-scrollbar-button:increment {
        // color has to be hardcoded because scrollbar styling doesnt work with fill propery
        background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy40MSA4LjU5TDEyIDEzLjE3bDQuNTktNC41OEwxOCAxMGwtNiA2LTYtNiAxLjQxLTEuNDF6IiBmaWxsPSJ3aGl0ZSIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMFYweiIvPjwvc3ZnPg==);
    }

    ::-webkit-scrollbar-button:decrement {
        background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy40MSAxNS40MUwxMiAxMC44M2w0LjU5IDQuNThMMTggMTRsLTYtNi02IDZ6IiBmaWxsPSJ3aGl0ZSIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
    }
}

app-header mat-icon.mat-icon.mat-icon-inline[svgIcon] {
    height: 100%;
    box-sizing: border-box;
    padding: 2.5vh 0; // when specified as a font its 5vh in a 10vh area
}

// https://github.com/zefoy/ngx-swiper-wrapper/issues/255
.swiper-button-prev,
.swiper-button-next {
    &[hidden] {
        display: none;
    }
}
