$amber: #e68900;
$black: #121212;
$white: #fff;
$blue: #448aff;
$green: #9ee493;
$red: #cf6679;
$gray: #808080;

$white-disabled: #555;
$header-bar-border: #333;

$boulder-icon-background-color: #34332e;
$boulder-icon-text-color: #959382; // color of boulder text on loading screen

$md-ampamber: (
    50: #fcf1e0,
    100: #f8dcb3,
    200: #f3c480,
    300: #eeac4d,
    400: #ea9b26,
    500: #e68900,
    600: #e38100,
    700: #df7600,
    800: #db6c00,
    900: #d55900,
    A100: #fffdfc,
    A200: #ffdcc9,
    A400: #ffbb96,
    A700: #ffab7d,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
